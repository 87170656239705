exports.components = {
  "component---src-pages-404-not-found-tsx": () => import("./../../../src/pages/404-not-found.tsx" /* webpackChunkName: "component---src-pages-404-not-found-tsx" */),
  "component---src-templates-business-segment-tsx": () => import("./../../../src/templates/BusinessSegment.tsx" /* webpackChunkName: "component---src-templates-business-segment-tsx" */),
  "component---src-templates-company-page-tsx": () => import("./../../../src/templates/CompanyPage.tsx" /* webpackChunkName: "component---src-templates-company-page-tsx" */),
  "component---src-templates-eqt-foundation-company-case-study-list-page-tsx": () => import("./../../../src/templates/eqtFoundation/CompanyCaseStudyListPage.tsx" /* webpackChunkName: "component---src-templates-eqt-foundation-company-case-study-list-page-tsx" */),
  "component---src-templates-eqt-foundation-research-project-case-study-list-page-tsx": () => import("./../../../src/templates/eqtFoundation/ResearchProjectCaseStudyListPage.tsx" /* webpackChunkName: "component---src-templates-eqt-foundation-research-project-case-study-list-page-tsx" */),
  "component---src-templates-eqt-foundation-resources-eqt-foundation-page-tsx": () => import("./../../../src/templates/eqtFoundation/ResourcesEqtFoundationPage.tsx" /* webpackChunkName: "component---src-templates-eqt-foundation-resources-eqt-foundation-page-tsx" */),
  "component---src-templates-home-page-tsx": () => import("./../../../src/templates/HomePage.tsx" /* webpackChunkName: "component---src-templates-home-page-tsx" */),
  "component---src-templates-landing-page-tsx": () => import("./../../../src/templates/LandingPage.tsx" /* webpackChunkName: "component---src-templates-landing-page-tsx" */),
  "component---src-templates-media-news-list-page-tsx": () => import("./../../../src/templates/media/NewsListPage.tsx" /* webpackChunkName: "component---src-templates-media-news-list-page-tsx" */),
  "component---src-templates-media-reports-and-presentations-page-tsx": () => import("./../../../src/templates/media/ReportsAndPresentationsPage.tsx" /* webpackChunkName: "component---src-templates-media-reports-and-presentations-page-tsx" */),
  "component---src-templates-people-page-tsx": () => import("./../../../src/templates/PeoplePage.tsx" /* webpackChunkName: "component---src-templates-people-page-tsx" */),
  "component---src-templates-person-tsx": () => import("./../../../src/templates/Person.tsx" /* webpackChunkName: "component---src-templates-person-tsx" */),
  "component---src-templates-portfolio-current-portfolio-page-tsx": () => import("./../../../src/templates/portfolio/CurrentPortfolioPage.tsx" /* webpackChunkName: "component---src-templates-portfolio-current-portfolio-page-tsx" */),
  "component---src-templates-portfolio-investment-characteristics-page-tsx": () => import("./../../../src/templates/portfolio/InvestmentCharacteristicsPage.tsx" /* webpackChunkName: "component---src-templates-portfolio-investment-characteristics-page-tsx" */),
  "component---src-templates-press-release-page-tsx": () => import("./../../../src/templates/PressReleasePage.tsx" /* webpackChunkName: "component---src-templates-press-release-page-tsx" */),
  "component---src-templates-search-results-page-tsx": () => import("./../../../src/templates/SearchResultsPage.tsx" /* webpackChunkName: "component---src-templates-search-results-page-tsx" */),
  "component---src-templates-stakeholder-page-tsx": () => import("./../../../src/templates/StakeholderPage.tsx" /* webpackChunkName: "component---src-templates-stakeholder-page-tsx" */),
  "component---src-templates-standard-page-tsx": () => import("./../../../src/templates/StandardPage.tsx" /* webpackChunkName: "component---src-templates-standard-page-tsx" */)
}

